/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from "react";
import { useState,useContext } from "react";
import DefaultBlogCard from "examples/Cards/BlogCards/DefaultBlogCard";
import routesEN from "routesEN";

// rellax
import Rellax from "rellax";
import MKButton from "components/MKButton";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
/* import Information from "pages/Blogs/SingleArticle/sections/Information";
import Steps from "pages/Blogs/SingleArticle/sections/Steps";
import OurEfforts from "pages/Blogs/SingleArticle/sections/OurEfforts";
import Features from "pages/Blogs/SingleArticle/sections/Features";
import Posts from "pages/Blogs/SingleArticle/sections/Posts";
import Support from "pages/Blogs/SingleArticle/sections/Support"; */
import {getArticles, getPages } from "../../hooks/airtable";
import { Context } from "../../Context";
import ContactUsOne from "pages/presentation/sections/Contact";
import TransparentTeamCard from "examples/Cards/TeamCards/TransparentTeamCard";

import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import team1 from "assets/images/bruce-mars.jpg";
import team2 from "assets/images/team-3.jpg";
import team3 from "assets/images/team-4.jpg";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
/* import routesEN from "routesEN"; */

import "./style.css";
import author1 from "assets/images/team-2.jpg";
import author2 from "assets/images/ivana-squares.jpg";
import author3 from "assets/images/marie.jpg";
// Images
import bgImage from "assets/images/bg5.jpg";

function Partenaires() {
  const headerRef = useRef(null);
  const [contentData, setContentData] = useState([]);
  const {langContext, setLangContext} = useContext(Context);
  const [isFetch, setIsFetch] = useState(false);
  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()    

    const socialIcons = (
      <>
        <Tooltip placement="top" title="Follow me!">
          <MKBox
            component="i"
            fontSize="1.125rem"
            className="fab fa-twitter"
            sx={{
              color: ({ palette: { socialMediaColors } }) => socialMediaColors.twitter.main,
              cursor: "pointer",
            }}
          />
        </Tooltip>
        <Tooltip placement="top" title="Follow me!">
          <MKBox
            component="i"
            fontSize="1.125rem"
            className="fab fa-dribbble"
            sx={{
              color: ({ palette: { socialMediaColors } }) => socialMediaColors.dribbble.main,
              cursor: "pointer",
            }}
          />
        </Tooltip>
        <Tooltip placement="top" title="Follow me!">
          <MKBox
            component="i"
            fontSize="1.125rem"
            className="fab fa-linkedin"
            sx={{
              color: ({ palette: { socialMediaColors } }) => socialMediaColors.linkedin.main,
              cursor: "pointer",
            }}
          />
        </Tooltip>
      </>
    );

  // Setting up rellax
/*   useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []); */

  useEffect(() => {
    console.log('ooooooo')
    async function fetchData() {
      const data = await getPages();
      setContentData(data);
      console.log(data)
     // console.log(contentData.find(item => item.fields.key === "Section 1 " + langContext));
      setIsFetch(true);
    }
    fetchData();

  }, []);

  return (
    <>
      <MKBox shadow="sm" py={0.25} sx={{position: "sticky", top: 0, zIndex: 10,backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",}}>
         <DefaultNavbar
         routes={langContext =="Français" ? routes : routesEN}
        action={{
          type: "external",
          route: "https://www.google.com",
          label: "buy now",
          color: "info",
        }}
        sticky
      />
      </MKBox>
      {isFetch ? (
      <>
      <MKBox
        ref={headerRef}
        minHeight="55vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687729041/Untitled_6_oxezoc.png"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {contentData.find(item => item.fields.key === "Section 1 " + langContext && item.fields.Page === "Partenaires").fields.Titre}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mb={2}
              mr={{ xs: 0, sm: 6 }}
              pr={{ xs: 0, sm: 6 }}
            >
               {contentData.find(item => item.fields.key === "Section 1 " + langContext && item.fields.Page === "Partenaires").fields.Texte}
            </MKTypography>

            {/* <MKButton style={{marginTop:"5px"}} variant="gradient" color="primary" sx={{ width:"200px" }} onClick={()=>{executeScroll()}}>
            {langContext === "Français" ? "Rejoindre" : "Join"}
            </MKButton> */}
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        
        <MKBox component="section" py={6}>
          <Container>
            
          <MKBox component="section" py={7}>
      <Container>
        <Grid container spacing={3} >
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "Gustave Roussy " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "Gustave Roussy" }}
              title={contentData.find(item => item.fields.key === "Gustave Roussy " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "Gustave Roussy " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "Gustave Roussy " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "Gustave Roussy " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "Fundacio Privada Institut d’Investigacio Oncollogica de Vall d’Hebron " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "Fundacio Privada Institut d’Investigacio Oncollogica de Vall d’Hebron" }}
              title={contentData.find(item => item.fields.key === "Fundacio Privada Institut d’Investigacio Oncollogica de Vall d’Hebron " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "Fundacio Privada Institut d’Investigacio Oncollogica de Vall d’Hebron " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "Fundacio Privada Institut d’Investigacio Oncollogica de Vall d’Hebron " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "Fundacio Privada Institut d’Investigacio Oncollogica de Vall d’Hebron " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "Stichting Het Nederlands Kanker Instituut " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "Stichting Het Nederlands Kanker Instituut" }}
              title={contentData.find(item => item.fields.key === "Stichting Het Nederlands Kanker Instituut " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "Stichting Het Nederlands Kanker Instituut " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "Stichting Het Nederlands Kanker Instituut " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "Stichting Het Nederlands Kanker Instituut " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "Universitatetsklinikum Essen " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "Universitatetsklinikum Essen" }}
              title={contentData.find(item => item.fields.key === "Universitatetsklinikum Essen " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "Universitatetsklinikum Essen " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "Universitatetsklinikum Essen " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "Universitatetsklinikum Essen " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "PRIMAA " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "PRIMAA" }}
              title={contentData.find(item => item.fields.key === "PRIMAA " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "PRIMAA " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "PRIMAA " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "PRIMAA " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "Association pour la Recherche sur les Tumeurs du Rein " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "Association pour la Recherche sur les Tumeurs du Rein" }}
              title={contentData.find(item => item.fields.key === "Association pour la Recherche sur les Tumeurs du Rein " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "Association pour la Recherche sur les Tumeurs du Rein " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "Association pour la Recherche sur les Tumeurs du Rein " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "Association pour la Recherche sur les Tumeurs du Rein " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "Centre Hospitalier Universitaire de Rennes " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "Centre Hospitalier Universitaire de Rennes" }}
              title={contentData.find(item => item.fields.key === "Centre Hospitalier Universitaire de Rennes " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "Centre Hospitalier Universitaire de Rennes " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "Centre Hospitalier Universitaire de Rennes " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "Centre Hospitalier Universitaire de Rennes " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "Fakultni Nemocnice Olomouc " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "Fakultni Nemocnice Olomouc" }}
              title={contentData.find(item => item.fields.key === "Fakultni Nemocnice Olomouc " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "Fakultni Nemocnice Olomouc " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "Fakultni Nemocnice Olomouc " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "Fakultni Nemocnice Olomouc " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "Fondazione IRCCS Istituto Nazionale Dei Tumori " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "Fondazione IRCCS Istituto Nazionale Dei Tumori" }}
              title={contentData.find(item => item.fields.key === "Fondazione IRCCS Istituto Nazionale Dei Tumori " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "Fondazione IRCCS Istituto Nazionale Dei Tumori " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "Fondazione IRCCS Istituto Nazionale Dei Tumori " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "Fondazione IRCCS Istituto Nazionale Dei Tumori " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "Instituto de Investigación Hospital 12 de Octubre " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "Instituto de Investigación Hospital 12 de Octubre" }}
              title={contentData.find(item => item.fields.key === "Instituto de Investigación Hospital 12 de Octubre " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "Instituto de Investigación Hospital 12 de Octubre " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "Instituto de Investigación Hospital 12 de Octubre " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "Instituto de Investigación Hospital 12 de Octubre " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "Medizinische Universitaet Wien " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "Medizinische Universitaet Wien" }}
              title={contentData.find(item => item.fields.key === "Medizinische Universitaet Wien " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "Medizinische Universitaet Wien " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "Medizinische Universitaet Wien " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "Medizinische Universitaet Wien " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "PRIMAA " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "PRIMAA" }}
              title={contentData.find(item => item.fields.key === "PRIMAA " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "PRIMAA " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "PRIMAA " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "PRIMAA " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "Queen Mary University of London " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "Queen Mary University of London" }}
              title={contentData.find(item => item.fields.key === "Queen Mary University of London " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "Queen Mary University of London " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "Queen Mary University of London " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "Queen Mary University of London " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "Resilience " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "Resilience" }}
              title={contentData.find(item => item.fields.key === "Resilience " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "Resilience " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "Resilience " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "Resilience " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "Servicio Madrileño de Salud " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "Servicio Madrileño de Salud" }}
              title={contentData.find(item => item.fields.key === "Servicio Madrileño de Salud " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "Servicio Madrileño de Salud " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "Servicio Madrileño de Salud " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "Servicio Madrileño de Salud " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "Stichting Het Nederlands Kanker Instituut " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "Stichting Het Nederlands Kanker Instituut" }}
              title={contentData.find(item => item.fields.key === "Stichting Het Nederlands Kanker Instituut " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "Stichting Het Nederlands Kanker Instituut " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "Stichting Het Nederlands Kanker Instituut " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "Stichting Het Nederlands Kanker Instituut " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }} >
          <DefaultBlogCard
              image={contentData.find(item => item.fields.key === "Stichting International Kidney Cancer Coalition " + langContext && item.fields.Page === "Partenaires").fields.Image[0].url}
              category={{ color: "primary", label: "Stichting International Kidney Cancer Coalition" }}
              title={contentData.find(item => item.fields.key === "Stichting International Kidney Cancer Coalition " + langContext && item.fields.Page === "Partenaires").fields.Titre}
              description={contentData.find(item => item.fields.key === "Stichting International Kidney Cancer Coalition " + langContext && item.fields.Page === "Partenaires").fields.Texte}
              short={contentData.find(item => item.fields.key === "Stichting International Kidney Cancer Coalition " + langContext && item.fields.Page === "Partenaires").fields.Texte_short}
              action={{ type: "external", route: contentData.find(item => item.fields.key === "Stichting International Kidney Cancer Coalition " + langContext && item.fields.Page === "Partenaires").fields.Lien }}
            />
          </Grid>

          
        </Grid>
      </Container>
    </MKBox>

          </Container>
        </MKBox>

              

        <div ref={myRef}>
          <ContactUsOne/>
        </div>
        {/* <Steps />
        <OurEfforts />
        <Features />
        <Posts />
        <Support /> */}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      </>): null
      }
    </>
  );
}

export default Partenaires;
