/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from "react";
import { useState,useContext } from "react";
import Icon from "@mui/material/Icon";
import routesEN from "routesEN";

// rellax
import Rellax from "rellax";
import MKButton from "components/MKButton";
//import routesEN from "routesEN";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKPagination from "components/MKPagination";
import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard";


// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/Blogs/SingleArticle/sections/Information";
import Steps from "pages/Blogs/SingleArticle/sections/Steps";
import OurEfforts from "pages/Blogs/SingleArticle/sections/OurEfforts";
import Features from "pages/Blogs/SingleArticle/sections/Features";
import Posts from "pages/Blogs/SingleArticle/sections/Posts";
import Support from "pages/Blogs/SingleArticle/sections/Support";
import {getArticles, getPages } from "../../../src/hooks/airtable";
import { Context } from "../../Context";
import ContactUsOne from "pages/presentation/sections/Contact";
import '../../style.css'

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import "./style.css";

// Images
import bgImage from "assets/images/bg5.jpg";

function SingleArticle() {
  const headerRef = useRef(null);
  const [contentData, setContentData] = useState([]);
  const [articles, setArticles] = useState([]);
  const [pageArt, setPageArt] = useState(1);
  const [rangeArt, setRangeArt] = useState(0);
  const {langContext, setLangContext} = useContext(Context);
  const [isFetch, setIsFetch] = useState(false);
  const [isFetch2, setIsFetch2] = useState(false);
  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()    

  // Setting up rellax
/*   useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []); */

  useEffect(() => {
    async function fetchData() {
      const data = await getPages();
      setContentData(data);
      setIsFetch(true);
    }
    fetchData();

  }, []);

  useEffect(() => {
    async function fetchData() {
      const dataArticles = await getArticles();
      setArticles(dataArticles);
      setIsFetch2(true)
    }
    fetchData();

  }, []);

  const actionProps = {
    type: "internal",
    route: "/pages/landing-pages/rental",
    color: "info",
    label: langContext == "Français" ? "Lire plus" : "Read more",
  };
  const articlesSort = articles.filter(art => art.fields.Langue == langContext).filter(art=>art.fields.Status == "Publié").sort((a, b) => new Date(b.fields.Date) - new Date(a.fields.Date))

  return (
    <>
      <MKBox shadow="sm" py={0.25} sx={{position: "sticky", top: 0, zIndex: 10,backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",}}>
        {/* <DefaultNavbar
          routes={langContext =="Français" ? routes : routesEN}
          action={{
            type: "external",
            route: "https://www.creative-tim.com/product/material-kit-pro-react",
            label: "buy now",
            color: "info",
          }}
          brand={"Prevalung"}
            sticky
            transparent
            relative
            center
        /> */}
        <DefaultNavbar
     routes={langContext =="Français" ? routes : routesEN}
        action={{
          type: "external",
          route: "https://www.google.com",
          label: "buy now",
          color: "info",
        }}
        sticky
      />
      </MKBox>
      {isFetch ? (
      <>
      <MKBox
        ref={headerRef}
        minHeight="55vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1715674138/bgBlog_n8c4yk.png"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKTypography
              variant="h1"
              color="white"
              className='font-face-db'
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {contentData.find(item => item.fields.key === "Section 1 " + langContext && item.fields.Page === "Blog").fields.Titre}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              className='font-face-db'
              mb={2}
              mr={{ xs: 0, sm: 6 }}
              pr={{ xs: 0, sm: 6 }}
            >
               {contentData.find(item => item.fields.key === "Section 1 " + langContext && item.fields.Page === "Blog").fields.Texte}
            </MKTypography>

            {/* <MKButton style={{marginTop:"5px"}} variant="gradient" color="primary" sx={{ width:"200px" }} onClick={()=>{executeScroll()}}>
            {langContext === "Français" ? "Rejoindre" : "Join"}
            </MKButton> */}
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {isFetch2 ? (
        <MKBox component="section" py={3}>
          <Container>
            <Grid container spacing={3} sx={{ mt: 3 }}>
                {articlesSort.filter(art => articlesSort.indexOf(art) <= rangeArt + 5).filter(art => articlesSort.indexOf(art) >= rangeArt).map((article) => (
                <Grid item xs={12} md={6} lg={4}>
                <MKBox mt={3}>
                  <SimpleBookingCard
                    image={article.fields.Image[0].url}
                    title={article.fields.Titre}
                    description={article.fields.Extrait_raccourci}
                    categories={[article.fields.Date]}
                    action={{
                      type: "internal",
                      route: "/blog/"+article.id,
                      color: "info",
                      label: langContext == "Français" ? "Lire plus" : "Read more",}}
                  />
                </MKBox>
              </Grid>
                ))}
            </Grid>
            <MKBox mt={5}>
          <MKPagination>
{/*             <MKPagination item>
              <Icon>keyboard_arrow_left</Icon>
            </MKPagination> */}
            {articlesSort.filter(art => art.fields.Langue == langContext).filter(art => articlesSort.indexOf(art) < articlesSort.length/6).map((page) => (
              <div>
                {pageArt == articlesSort.indexOf(page)+1 ? (
                <MKPagination item active>
                  {articlesSort.indexOf(page)+1}
                </MKPagination>
                ) : (
                <MKPagination item 
                  onClick={() => {            
                    setRangeArt((articlesSort.indexOf(page))*6);
                      setPageArt(articlesSort.indexOf(page)+1);
                  }}
                >
                  {articlesSort.indexOf(page)+1}
                </MKPagination>
                )}
              </div>
            ))}

            {/* <MKPagination item>
              <Icon>keyboard_arrow_right</Icon>
            </MKPagination> */}
          </MKPagination>
        </MKBox>
          </Container>
        </MKBox>
        ) : null}
        <div ref={myRef}>
          <ContactUsOne/>
        </div>
        {/* <Steps />
        <OurEfforts />
        <Features />
        <Posts />
        <Support /> */}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      </>): null
      }
    </>
  );
}

export default SingleArticle;
