/* eslint-disable no-unused-vars */
const AirtablePlus = require("airtable-plus");

const API_KEY = "patrlS4PzLg5ECoMh.10d41145e0d94ce75a3b4a2b5138f9d97ac3367c8f52827740e5a128ce348d52";
const BASE = "app2ZHmpCasy98MrS";



const airtableArticles = new AirtablePlus({
  baseID: BASE,
  apiKey: API_KEY,
  tableName: "Articles",
});
const airtablePages = new AirtablePlus({
  baseID: BASE,
  apiKey: API_KEY,
  tableName: "Textes Site Web",
});
const airtableContact = new AirtablePlus({
  baseID: BASE,
  apiKey: API_KEY,
  tableName: "Contact",
});

export async function getArticles() {
  /* const filter = `({Space} = '${space}')`;
  const res2 = await airtableArticles.read({ filterByFormula: filter }); */
  const res = await airtableArticles.read();
  return res;
}
export const getPages = async () => {
  const res = await airtablePages.read();
  return res;
};

export async function getPage() {
  const res = await airtablePages.read();
  return res;
}

export async function sendMessage(Nom, Email, Message) {
  const res = await airtableContact.create({
    Nom: Nom,
    Email: Email,
    Message: Message,
  });
  return res;
}