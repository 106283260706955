import React, { useState } from "react";
 
export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
    const [langContext, setLangContext] = useState("Anglais");
 
    return (
        <Context.Provider value={{ langContext, setLangContext }}>
            {children}
        </Context.Provider>
    );
};
