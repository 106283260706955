/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import {useContext } from "react";
import { Context } from "../../../Context";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from 'react-responsive'

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import zIndex from "@mui/material/styles/zIndex";

function DefaultFooter({ content }) {
  const { brand, socials, menus, copyright } = content;
const isDesktopOrLaptop = useMediaQuery({
  query: '(min-width: 1224px)'
})
const {langContext, setLangContext} = useContext(Context);

  return (
    <>
    {isDesktopOrLaptop ? (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3 }}>
            <MKBox>
              <Link to={brand.route}>
                <div style={{display:"flex",flexDirection:"row",width:"75vh"}}>
                  <div style={{display:"flex",flexDirection:"column"}}>
                  <img src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687116535/europe_vt8x71.png"} alt={brand.name} width="135px" height="80px"  />
                  <MKTypography
                    display="block"
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                  
                    style={{color:'#145896',marginTop:5}}
                  >Funded by the EU</MKTypography>
                </div>
                <MKTypography
                  display="block"
                  variant="button"
                  fontWeight="regular"
                  
                
                  style={{marginLeft:"1.5rem"}}
                >
Funded by the European Union. This Project Has Received Funding From The European Union’s Horizon Europe Research And Innovation Programme Under Grant Agreement No 101104801. Views and opinions expressed are however those of the author(s) only
and do not necessarily reflect those of the European Union. Neither the European Union nor the granting authority can be held responsible for them.            </MKTypography>
            </div>
              </Link>
             {/*  <MKTypography variant="h6">Care1</MKTypography> */}
            </MKBox>
            {/* <MKBox display="flex" alignItems="center" mt={3}>
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox> */}
          </Grid>
          <Grid item xs={6} md={2} sx={{ mb: 3 }}>
          </Grid>
          <Grid item xs={6} md={2} sx={{ mb: 3 }}>
            <MKTypography
              display="block"
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              mb={1}
            >
              
            </MKTypography>
            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  
                </MKTypography>
              </MKBox>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  
                </MKTypography>
              </MKBox>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
          <Grid item xs={6} md={2} sx={{ mb: 3 }}>
            <MKTypography
              display="block"
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              mb={1}
            >
              {langContext === "Français" ? "Le Projet":"The Project"}
            </MKTypography>
            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  href="/home"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  {langContext === "Français" ? "Etude clinique":"Clinical Trial"}
                   
                </MKTypography>
              </MKBox>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  href="/home"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  {langContext === "Français" ? "Projet de recherche":"Research Project"}
                </MKTypography>
              </MKBox>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  href="/cohortes"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  {langContext === "Français" ? "Associations patients":"Patient’s associations"}
                </MKTypography>
              </MKBox>
             
            </MKBox>
          </Grid>
          <Grid item xs={6} md={2} sx={{ mb: 3 }}>
            <MKTypography
              display="block"
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              mb={1}
            >
              {langContext === "Français" ? "Autres":"Others"}
            </MKTypography>
            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  href="/equipe"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  {langContext === "Français" ? "L'équipe":"Team"}
                </MKTypography>
              </MKBox>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  href="/partenaires"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  {langContext === "Français" ? "Partenaires":"Partners"}
                </MKTypography>
              </MKBox>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  href="/blog"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  {langContext === "Français" ? "Blog":"Blog"}
                </MKTypography>
              </MKBox>
              {/* <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  href="/publications"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  {langContext === "Français" ? "Publications":"Publications"}
                </MKTypography>
              </MKBox> */}
              
            </MKBox>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
          <MKTypography
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >Copyright © 2024 Care1.{/* {copyright} */}</MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
    ) : (
      <MKBox component="footer">
         {/* <div style={{display:"flex", justifyContent:"center",flexDirection:"column",zIndex:10}}>
            <MKTypography
              variant="h1"
              color="#1A1919"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              <span className="font-title">HEY</span>
            </MKTypography>
          </div> */}
       {/*  <div style={{zIndex:10}}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3 }}>
            <MKBox>
              <Link to={brand.route}>
                <div style={{display:"flex",flexDirection:"column", zIndex:10}}>
                <img src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687116535/europe_vt8x71.png"} alt={brand.name} width="170px" height="80px"  />
                <MKTypography
                  display="block"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                  mb={1}
                  style={{marginLeft:"1.5rem"}}
                >
              This project has received funding from the European Union’s Horizon Europe research and innovation programme under grant agreement No 101095604.
            </MKTypography>
            </div>
              </Link>
              <MKTypography variant="h6">Prevalung EU</MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" mt={3}>
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>

          <Grid item xs={6} md={2} sx={{ mb: 3 }}>
            <MKTypography
              display="block"
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              mb={1}
            >
              Le Projet
            </MKTypography>
            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  Page 1
                </MKTypography>
              </MKBox>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  Page 2
                </MKTypography>
              </MKBox>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  Page 3
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
          <Grid item xs={6} md={2} sx={{ mb: 3 }}>
            <MKTypography
              display="block"
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              mb={1}
            >
              Titre
            </MKTypography>
            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  Page 1
                </MKTypography>
              </MKBox>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  Page 2
                </MKTypography>
              </MKBox>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  Page 3
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
      </div> */}

    </MKBox>
    )}
    </>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
