/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {getArticles, getPages } from "../../../hooks/airtable";
import { useEffect, useRef, useState,useContext } from "react";
import { useMediaQuery } from 'react-responsive'
import { Context } from "../../../Context";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function Counters() {
  const [contentData, setContentData] = useState([]);
  const {langContext, setLangContext} = useContext(Context);
  const [isFetch, setIsFetch] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  useEffect(() => {
    async function fetchData() {
      const data = await getPages();
      //console.log('ree',data.find(item => item.fields.key === "Nombre 1 " + langContext && item.fields.Page === "Accueil"))
      setContentData(data);
      setIsFetch(true)
    }
    fetchData();

  }, []);
  return (
    <>
    {isFetch ? (
    <MKBox component="section" py={3}>
      <Container>
      <Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
      <Grid item xs={12} md={3}>
            <DefaultCounterCard
              color='warning'
              count={parseInt(contentData.find(item => item.fields.key === "Nombre 1 ligne 1 " + langContext && item.fields.Page === "Accueil").fields.Titre)}
              suffix=""
              title={contentData.find(item => item.fields.key === "Nombre 1 ligne 1 " + langContext && item.fields.Page === "Accueil").fields.Texte}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              //description="Lorem ipsum dolor sit amet, consectetur adipiscing"
              color='warning'
              count={parseInt(contentData.find(item => item.fields.key === "Nombre 2 ligne 1 " + langContext && item.fields.Page === "Accueil").fields.Titre)}
              suffix="+"
              title={contentData.find(item => item.fields.key === "Nombre 2 ligne 1 " + langContext && item.fields.Page === "Accueil").fields.Texte}
              //description={contentData.find(item => item.fields.key === "Nombre 1 " + langContext && item.fields.Page === "Accueil").fields["Sous texte"]}
            />
          </Grid>
         
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              color='warning'
              count={parseInt(contentData.find(item => item.fields.key === "Nombre 3 ligne 1 " + langContext && item.fields.Page === "Accueil").fields.Titre)}
              suffix=""
              title={contentData.find(item => item.fields.key === "Nombre 3 ligne 1 " + langContext && item.fields.Page === "Accueil").fields.Texte}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              color='warning'
              count={parseInt(contentData.find(item => item.fields.key === "Nombre 4 ligne 1 " + langContext && item.fields.Page === "Accueil").fields.Titre)}
              suffix="+"
              title={contentData.find(item => item.fields.key === "Nombre 4 ligne 1 " + langContext && item.fields.Page === "Accueil").fields.Texte}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
    ): null}
    </>
  );
}

export default Counters;
