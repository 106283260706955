// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKPagination from "components/MKPagination";

// Material Kit 2 PRO React components
import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard";

// Images
import product1 from "assets/images/products/product-1-min.jpg";
import product2 from "assets/images/products/product-2-min.jpg";
import product3 from "assets/images/products/product-3-min.jpg";
import product4 from "assets/images/products/product-5-min.jpg";
import product5 from "assets/images/products/product-6-min.jpg";
import product6 from "assets/images/products/product-7-min.jpg";

import { useEffect, useRef, useState,useContext } from "react";
import {getArticles} from "../../../hooks/airtable";
import { Context } from "../../../Context";


function BlogPostTwo() {
  
  const [articles, setArticles] = useState([]);
  const {langContext, setLangContext} = useContext(Context);
  const [isFetch, setIsFetch] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const dataArticles = await getArticles();
      setArticles(dataArticles);
      setIsFetch(true)
    }
    fetchData();

  }, []);
  const actionProps = {
    type: "internal",
    route: "/pages/landing-pages/rental",
    color: "info",
    label: langContext == "Français" ? "Lire plus" : "Read more",
  };
  return (
    <>
    {isFetch ? (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 0 }}>
            {articles.filter(art => art.fields["Page d'accueil"] == true).filter(art => art.fields.Langue == langContext).map((article) => (
             <Grid item xs={12} md={6} lg={4}>
             <MKBox mt={3}>
               <SimpleBookingCard
                 image={article.fields.Image[0].url}
                 title={article.fields.Titre_raccourci}
                 description={article.fields.Extrait_raccourci}
                 categories={[article.fields.Date]}
                 action={{
                  type: "internal",
                  route: "/blog/"+article.id,
                  color: "info",
                  label: langContext == "Français" ? "Lire plus" : "Read more",}}
               />
             </MKBox>
           </Grid>
            ))}
        </Grid>
      </Container>
    </MKBox>
    ) : (
      null
    )}
  </>
  );
}

export default BlogPostTwo;