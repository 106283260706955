// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import coinbase from "assets/images/logos/medium-logos/logo-coinbase.svg";
import nasa from "assets/images/logos/medium-logos/logo-nasa.svg";
import netflix from "assets/images/logos/medium-logos/logo-netflix.svg";
import pinterest from "assets/images/logos/medium-logos/logo-pinterest.svg";
import spotify from "assets/images/logos/medium-logos/logo-spotify.svg";
import vodafone from "assets/images/logos/medium-logos/logo-vodafone.svg";
import { Context } from "../../../Context";
import { useEffect, useRef, useState,useContext } from "react";

function LogoAreaThree() {
  const {langContext, setLangContext} = useContext(Context);

  return (
    <MKBox py={0}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={8}
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h3" mb={1}>
          <span className="font-face-db">{langContext === "Français" ? "Partenaires" : "Partners"}</span>
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 , justifyContent:"center"}}>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687107895/Logo-gustave-roussy-removebg-preview_gto88s.png"} alt="igr" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1714042789/sermas-removebg-preview_gkl18j.png"} alt="NHS" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1714042788/stichting_het_nederlands_kanker_instituut_antoni_leeuwenhoek_nki_logo-removebg-preview_ivxawf.png"} alt="inserm" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2} >
            <MKBox style={{marginTop:10}} component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1714044819/Capture_d_e%CC%81cran_2024-04-25_a%CC%80_13.33.11-removebg-preview_tpuzsp.png"} alt="UNITO" width="100%" opacity={0.9} />
          </Grid>
         
          
          
          
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 , justifyContent:"center"}}>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1714042787/Med-Uni-Wien-Logo-removebg-preview_mxsdnn.png"} alt="erasmus-mc" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox style={{marginTop:10}} component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1714042786/cropped-fondazione-irccs-removebg-preview_doi4bk.png"} alt="ap-hm" width="100%" opacity={0.9} />
          </Grid>
          
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1714042786/1630650994487-removebg-preview_jindqd.png"} alt="o-link" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1714042786/2465_cricpp.png"} alt="seerave-foundation" width="100%" opacity={0.9} />
          </Grid>
          
          
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 , justifyContent:"center"}}>
        <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1714042787/qmul-logo-removebg-preview_ydfcc2.png"} alt="ku-leuven" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687116206/Gustave-removebg-preview_copie_kcq3h0.png"} alt="grt" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1714042787/Logo_UME_UKE_EN-scaled-removebg-preview_eng7wm.png"} alt="patients" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox style={{marginTop:5}} component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1714042786/49e66827-643c-4afb-b74e-794c61d862c6_ydr29m.png"} alt="fhpsj" width="100%" opacity={0.9} />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 , justifyContent:"center"}}>
        <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1714042786/fn-olomouc-logo_mqy6c3.png"} alt="ku-leuven" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1714042786/Logotipo_del_SERMAS__RPS_10-03-2012_-removebg-preview_oijkac.png"} alt="grt" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1714042786/ikcc-logo-mainline2x_zuovvr.png"} alt="patients" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2} style={{marginLeft:"20px"}}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1714042788/UVWwBK41_400x400-removebg-preview_wsgbhe.png"} alt="bio-me" width="70%" opacity={0.9} />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default LogoAreaThree;